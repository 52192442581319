import React, { useEffect, useState } from "react";
import { upperCaseFirstChar } from "../utils/utils";
import TableRow from "./TableRow";
import usePagination from "../../common/hooks/usePagination";
import Pagination from "../../common/components/Pagination";
import Tooltip from "./Tooltip";

import useFilter from "../hooks/useFilter";
import Filter from "./Filter";
import Icon from "./Icon";

// Main Table Component.
const Table = ({
  // Base props:
  headers = [],
  title,
  rows, // Initial data array
  appendRows, // Prop to append custom rows at bottom of table.

  // Data manipulation props:
  sortOptions = { field: "", type: "", order: false },
  formatFunction,

  // Props for table row options:
  tableActions,
  onLink = undefined,
  onToggle = undefined,
  onTogglePermission,
  onToggleTooltip,
  onTogglePrompt = undefined,
  toggleDisabled,
  onRemove = undefined,
  onRemovePermission,
  removeDisabled,
  draggable = false,
  onDrag,
  identifier,

  // Style props:
  style, // Table Style prop.
  align, // Table Cell alignment. Default aligns right except the first child
  rounded = true,
  bordered = false,
  hideHeader = false,

  // Pagination props
  hasPagination = true,
  pageSize = 50,

  // Filter(search) props:
  hasFilter = true,
  filterFields = ["name"],
  filterPlaceholder,

  // Custom button layout
  customButton,
  customButtonToggle,

  // Expandable row props:
  expand = false,
  expandFormat = () => {},
  expandContent = () => <></>,
  nestedTableFormat,
}) => {
  // Toggle sorting by active/inactive.

  const [sortingOptions, setSortingOptions] = useState(sortOptions);
  const [tableData, setTableData] = useState();

  // Data is filtered. (Returns the same array if filter empty)
  const { filter, updateFilter, filteredData } = useFilter(rows, filterFields);

  // Filtered data is paginated
  const { totalPages, currentPage, pageData, handlePageChange } = usePagination(
    pageSize, // Page size
    filteredData, // Filtered campaigns (all campaigns if search is empty.)
    sortingOptions,
    customButtonToggle
  );

  // Format data for Table component:
  useEffect(() => {
    if (pageData) {
      setTableData(pageData);
      if (formatFunction) {
        // Select only data being displayed with provided formatFunction.
        setTableData(pageData?.map(formatFunction));
      } else {
        setTableData(pageData);
      }
    }
  }, [pageData, formatFunction, setTableData]);

  // Expand Data is not sorted like the rows are normally.
  // If using expanded rows, be sure to sort the rows prop beforehand.
  let expandData = expand ? rows?.map(expandFormat) : [];

  const handleHeaderClick = (header) => {
    if (header.hasOwnProperty("type")) {
      sortingOptions.field === header.id
        ? setSortingOptions({
            ...sortingOptions,
            order: !sortingOptions.order, // Toggle ascending/descending.
          })
        : setSortingOptions({
            ...sortingOptions,
            field: header.id,
            type: header.type,
          });
    }
  };

  const handleDrag = (idx, diff) => {
    if (diff !== 0) {
      let newIdx = idx + diff;
      onDrag(
        pageData.map((q, rowIdx) => {
          // Dragging upwards:
          if (diff < 0) {
            // Assign new index to dragged row:
            if (rowIdx === idx) {
              return { ...q, [sortOptions.field]: newIdx + 1 };
            }
            // Increase index on elements between initial position and end position:
            if (rowIdx >= newIdx && rowIdx < idx) {
              return { ...q, [sortOptions.field]: q[sortOptions.field] + 1 };
            }
          }

          // Dragging downwards:
          if (diff > 0) {
            // Assign new index to dragged row:
            if (rowIdx === idx) {
              return { ...q, [sortOptions.field]: newIdx + 1 };
            }
            // Decrease index on elements between initial position and end position:
            if (rowIdx <= newIdx && rowIdx > idx) {
              return { ...q, [sortOptions.field]: q[sortOptions.field] - 1 };
            }
          }

          // No difference, stays the same.
          return q;
        })
      );
    }
  };

  const renderTableHeader = () => {
    // For every header:
    return React.Children.toArray(
      headers.map((header, idx) => {
        // Ignore these headers.
        if (header.text === "isActive" || header.text === "id") {
          return <React.Fragment></React.Fragment>;
        }

        if (expand && idx === 0) {
          // Return final header and the action header.
          return (
            <React.Fragment>
              <th key={idx} style={{ width: "30px" }}>
                <Icon icon="expand_more" direction="column" />
              </th>
              {header.tooltip ? (
                <Tooltip
                  style={{
                    display: "table-cell",
                    // padding: "25px 15px 0px 15px",
                    backgroundColor: "white",
                  }}
                  delay={0}
                  content={header.tooltip}
                  wrapper="th"
                >
                  <div onClick={() => handleHeaderClick(header)}>
                    {upperCaseFirstChar(header.text)}
                  </div>
                </Tooltip>
              ) : (
                <th
                  style={
                    header.hasOwnProperty("type") ? { cursor: "pointer" } : {}
                  }
                  onClick={() => handleHeaderClick(header)}
                >
                  {upperCaseFirstChar(header.text)}
                </th>
              )}
            </React.Fragment>
          );
        }

        // If the table has actions and we're at the last header:
        if (tableActions && idx === headers.length - 1) {
          // Return final header and the action header.
          return (
            <React.Fragment>
              {header.tooltip ? (
                <Tooltip
                  style={{
                    display: "table-cell",
                    // padding: "25px 15px 0px 15px",
                    backgroundColor: "white",
                  }}
                  delay={0}
                  content={header.tooltip}
                  wrapper="th"
                >
                  <div onClick={() => handleHeaderClick(header)}>
                    {upperCaseFirstChar(header.text)}
                  </div>
                </Tooltip>
              ) : (
                <th
                  style={
                    header.hasOwnProperty("type") ? { cursor: "pointer" } : {}
                  }
                  onClick={() => handleHeaderClick(header)}
                >
                  {upperCaseFirstChar(header.text)}
                </th>
              )}
              <th key={idx + 1}>Actions</th>
            </React.Fragment>
          );
        }

        // Return header tag
        return (
          <>
            {header.tooltip ? (
              <Tooltip
                style={{
                  display: "table-cell",
                  // padding: "25px 15px 0px 15px",
                  backgroundColor: "white",
                }}
                delay={0}
                content={header.tooltip}
                wrapper="th"
              >
                <div onClick={() => handleHeaderClick(header)}>
                  {upperCaseFirstChar(header.text)}
                </div>
              </Tooltip>
            ) : (
              <th
                style={
                  header.hasOwnProperty("type") ? { cursor: "pointer" } : {}
                }
                onClick={() => handleHeaderClick(header)}
              >
                {upperCaseFirstChar(header.text)}
              </th>
            )}
          </>
        );
      })
    );
  };

  // Function to render table body:
  const renderTableBody = () => {
    // Catch undefined and empty arrays.
    if (!tableData || !tableData.length) {
      return (
        <tr>
          <td
            style={{ textAlign: "center" }}
            colSpan={
              tableActions
                ? expand
                  ? headers.length + 2
                  : headers.length + 1
                : expand
                ? headers.length + 1
                : headers.length
            }
          >
            No rows found
          </td>
        </tr>
      );
    }

    // Return a TableRow component for each row.
    return React.Children.toArray(
      tableData?.map((row, idx) => {
        let className = "indicator-1";
        let { isActive, rowClassName, ...rest } = row || {};

        if (isActive !== undefined && !isActive) {
          className = "disabled";
        }

        if (row?.type && row?.type === "Include") {
          className = "indicator-4";
        }

        if (row?.type && row?.type === "Exclude") {
          className = "indicator-3";
        }

        return (
          <TableRow
            key={
              expand || nestedTableFormat !== undefined ? row[identifier] : idx
            }
            rowKey={idx}
            row={rest}
            align={align}
            className={className}
            tableActions={tableActions}
            onLink={onLink}
            onToggle={onToggle}
            onTogglePermission={onTogglePermission}
            onToggleTooltip={onToggleTooltip}
            onTogglePrompt={onTogglePrompt}
            toggleDisabled={toggleDisabled}
            onRemove={onRemove}
            onRemovePermission={onRemovePermission}
            removeDisabled={removeDisabled}
            expand={expand}
            draggable={draggable}
            expandData={expandData[idx]}
            expandContent={expandContent}
            nestedTableFormat={nestedTableFormat}
            handleDrag={handleDrag}
          />
        );
      })
    );
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "20px",
        align: "center",
        boxShadow: bordered
          ? "0px 4px 8px 0px rgba(0,0,0,0.2),0px 6px 20px 0px rgba(0,0,0,0.2)"
          : "",
        ...style,
      }}
    >
      <div style={{ overflow: "auto" }}>
        {title ? <label>{title}</label> : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {hasFilter && (
            <Filter
              placeholder={filterPlaceholder}
              keyword={filter}
              setKeyword={updateFilter}
            />
          )}
          {customButton}
        </div>
        <table className={rounded ? "rounded" : undefined} style={{ ...style }}>
          <thead
            style={{
              border: "none",
              borderBottom: headers.length
                ? "1px solid rgba(var(--primary-black),1)"
                : "",
              position: "relative",
              visibility: hideHeader ? "collapse" : "visible",
            }}
          >
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody style={{ border: "none", position: "relative" }}>
            {renderTableBody()}

            {appendRows}
          </tbody>
        </table>
      </div>
      {hasPagination && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Table;
