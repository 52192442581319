import { UserManager } from "oidc-client";

// Information for oidc connetion.
const userManagerConfig = {
  authority: "https://kc.adquire.com:8443/realms/AdQuire",
  client_id: "AdquireManager",
  redirect_uri:
    window.location.href === `${window.location.origin}/`
      ? `${window.location.origin}/callback_page`
      : window.location.href,
  post_logout_redirect_uri: window.location.origin,
  response_type: "id_token token",
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
};

// Prepare userManager for oidc connection with userManagerConfig.
const userManager = new UserManager(userManagerConfig);

export default userManager;
