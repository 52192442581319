import React from "react";

const Filter = ({
  keyword,
  setKeyword,
  placeholder = "Filter rows...",
  list,
  onInput,
}) => {
  return (
    <input
      type="search"
      style={{ width: "300px", marginBottom: "5px" }}
      placeholder={placeholder}
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
      list={list}
      onInput={onInput}
    />
  );
};

export default Filter;
