import React, { useEffect, useState } from "react";
import "../styles/tooltip.css";

const Tooltip = ({
  children,
  content,
  wrapper = "div",
  style,
  direction = "top",
  onClick = false,
  delay = 800,
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  useEffect(() => {
    // Clear timeout on unmount.
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  if (onClick) {
    const clickShowTip = () => {
      clearTimeout(timeout);
      setActive(true);
    };

    const clickHideTip = () => {
      timeout = setTimeout(() => {
        setActive(false);
      }, delay);
    };

    return (
      <div
        className="tooltip-container"
        style={style}
        onClick={() => {
          clickShowTip();
          clickHideTip();
        }}
      >
        {children}
        {active && content && (
          <div className={`tooltip-tip ${direction}`}>{content}</div>
        )}
      </div>
    );
  }

  // Show Tooltip after delay.
  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  // Hide Tooltip
  const hideTip = () => {
    clearTimeout(timeout);
    setActive(false);
  };

  switch (wrapper) {
    case "th":
      return (
        <th
          className="tooltip-container"
          style={style}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          {children}
          {active && content && (
            <div className={`tooltip-tip ${direction}`}>{content}</div>
          )}
        </th>
      );

    case "td":
      return (
        <td
          className="tooltip-container"
          style={style}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          {children}
          {active && content && (
            <div className={`tooltip-tip ${direction}`}>{content}</div>
          )}
        </td>
      );

    default:
      return (
        <div
          className="tooltip-container"
          style={style}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          {children}
          {active && content && (
            <div className={`tooltip-tip ${direction}`}>{content}</div>
          )}
        </div>
      );
  }
};

export default Tooltip;
