import { setUser } from "./authSlice";
import userManager from "./userManager";

export const login = () => (dispatch) => {
  userManager.signinRedirect().catch((error) => {
    console.log(error);
  });
};

export const handleRedirectCallback = () => (dispatch) => {
  userManager
    .signinRedirectCallback(window.location.href)
    .then((res) => {
      dispatch(setUser(JSON.parse(res.toStorageString())));
      window.location.replace(
        `${window.location.href.split("/callback_page")[0]}/campaigns`
      );
    })
    .catch((error) => {
      console.log(error);
      // dispatch(setError(error));
    });
};

export const logout = () => (dispatch) => {
  userManager
    .signoutRedirect()
    .then(() => {
      // Remove session data after logout.
      sessionStorage.removeItem(
        "oidc.user:https://kc.adquire.com:8443/realms/AdQuire:AdquireManager"
      );

      setUser(null);
    })
    .catch((error) => {
      // dispatch(setError(error));
      console.log(error);
    });
};
