import { createSlice } from "@reduxjs/toolkit";
import { Log } from "oidc-client";
import userManager from "./userManager";

Log.logger = console;

userManager.events.addUserLoaded(() => {
  console.log("User Loaded.");
});

userManager.events.addUserSignedIn(() => {
  console.log("Signed In");
});

userManager.events.addAccessTokenExpiring(() => {});

userManager.events.addAccessTokenExpired(() => {});

const loginSlice = createSlice({
  name: "auth",

  // Check for session token
  initialState: {
    user:
      JSON.parse(
        sessionStorage.getItem(
          "oidc.user:https://kc.adquire.com:8443/realms/AdQuire:AdquireManager"
        )
      ) || null,
    isLoading: false,
    error: null,
  },

  reducers: {
    // Login Redirect to Keycloak authentication.
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions.
export const { setUser, setLoading, setError } = loginSlice.actions;
// Export reducer.
export default loginSlice.reducer;
